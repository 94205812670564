export const CHECK_AUTH = 'getcare/sso/CHECK_AUTH';
export const SIGN_IN = 'getcare/sso/SIGN_IN';
export const SIGN_IN_WITH_GOOGLE = 'getcare/sso/SIGN_IN_WITH_GOOGLE';
export const SIGN_UP = 'getcare/sso/SIGN_UP';

// mutation
export const SAVE_AUTH_LOADING = 'getcare/sso/SAVE_AUTH_LOADING';
export const SAVE_AUTH_ERROR = 'getcare/sso/SAVE_AUTH_ERROR';
export const SAVE_AUTH_SIGNUP_SUCCESS = 'getcare/sso/SAVE_AUTH_SIGNUP_SUCCESS';
export const SAVE_AUTH = 'getcare/sso/SAVE_AUTH';
export const REMOVE_AUTH = 'getcare/sso/REMOVE_AUTH';
