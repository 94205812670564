import React, { Component, Suspense, useEffect } from 'react';
import { Switch, Route, useLocation, Redirect, BrowserRouter } from 'react-router-dom';
import {
  ThemeProvider,
  StylesProvider,
  createTheme,
} from '@material-ui/core/styles';
import { defaultTheme } from 'assets/jss/themes';

import routes from 'routes/routes';

import AppStyles from 'assets/jss/app';
import './App.scss';

const ScrollToTop = React.memo((props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Limgreen";
  }, [location.pathname]);

  return null;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: createTheme(defaultTheme)
    }
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
        <AppStyles/>
        <StylesProvider injectFirst>
          <div className="App">
            <Switch>
              {
                routes.map( (route,key) => {
                  return (
                    <Route
                      exact
                      key={key}
                      path={route.routes ? route.routes.map( r => (route.path.charAt(1) ? route.path : '') + r.path ) : route.path }
                      render={props => (<>
                        <ScrollToTop/>
                        <Suspense fallback={<div>Loading...</div>}>
                          <route.component
                            {...props}
                            routes={route.routes.map( r => { r.path = (route.path.charAt(1) ? route.path : '') + r.path; return r })}
                          />
                        </Suspense>
                      </>)}
                    />
                  );
                })
              }
              <Route path="*">
                <Redirect to="/"/>
              </Route>
            </Switch>
          </div>
        </StylesProvider>
      </ThemeProvider>
    );
  }
}

export default App;
