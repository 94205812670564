export const MAX_FILE_SIZE = 4*1024*1024;

export const listPageSizes = [50, 100, 150];
export const suggestionPageSize = 250;

export const ACTIVE = 1;
export const DEACTIVE = -1;
export const activeStatusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [DEACTIVE]: 'Ngừng hoạt động',
};

export const LOGISTIC_GETCARE_ID = 1;
export const LOGISTIC_CHANH_XE_ID = 2;

export const DEFAULT_MODULE = 'erp';

export const COMPANY_CUSTOMER_TYPE = 1;
export const RETAIL_CUSTOMER_TYPE = 2;
export const DOCTOR_CUSTOMER_TYPE = 3;
export const DISTRIBUTOR_CUSTOMER_TYPE = 4;
export const COLLABORATOR_CUSTOMER_TYPE = 5;

export const registerTypesMap = {
  [RETAIL_CUSTOMER_TYPE]: 'Khách lẻ',
  [COMPANY_CUSTOMER_TYPE]: 'Nhà thuốc/ Quầy thuốc',
  [DOCTOR_CUSTOMER_TYPE]: 'Bác sĩ',
  [DISTRIBUTOR_CUSTOMER_TYPE]: 'Nhà phân phối',
  [COLLABORATOR_CUSTOMER_TYPE]: 'Cộng tác viên'
};
export const registerTypes = [
  {
    id: RETAIL_CUSTOMER_TYPE,
    name: registerTypesMap[RETAIL_CUSTOMER_TYPE],
  },
  {
    id: COMPANY_CUSTOMER_TYPE,
    name: registerTypesMap[COMPANY_CUSTOMER_TYPE],
  },
  {
    id: DOCTOR_CUSTOMER_TYPE,
    name: registerTypesMap[DOCTOR_CUSTOMER_TYPE],
  },
  {
    id: DISTRIBUTOR_CUSTOMER_TYPE,
    name: registerTypesMap[DISTRIBUTOR_CUSTOMER_TYPE],
  },
  {
    id: COLLABORATOR_CUSTOMER_TYPE,
    name: registerTypesMap[COLLABORATOR_CUSTOMER_TYPE],
  }
];
export function isRetailsCustomer(customerTypeId) {
  return [RETAIL_CUSTOMER_TYPE, RETAIL_CUSTOMER_TYPE + ``].includes(customerTypeId);
}
export function isStoreCustomer(customerTypeId) {
  return [COMPANY_CUSTOMER_TYPE, COMPANY_CUSTOMER_TYPE + ``].includes(customerTypeId);
}
export function isDoctorCustomer(customerTypeId) {
  return [DOCTOR_CUSTOMER_TYPE, DOCTOR_CUSTOMER_TYPE + ``].includes(customerTypeId);
}
export function isDistributorCustomer(customerTypeId) {
  return [DISTRIBUTOR_CUSTOMER_TYPE, DISTRIBUTOR_CUSTOMER_TYPE + ``].includes(customerTypeId);
}
export function isCollaboratorCustomer(customerTypeId) {
  return [COLLABORATOR_CUSTOMER_TYPE, COLLABORATOR_CUSTOMER_TYPE + ``].includes(customerTypeId);
}

