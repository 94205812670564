import {
  GET_COUNTRY_LIST,
  GET_PROVINCE_LIST,
  GET_DISTRICT_LIST,
  GET_WARD_LIST,
  SAVE_PROVINCE_LIST,
  SAVE_COUNTRY_LIST,
  SAVE_DISTRICT_LIST,
  SAVE_WARD_LIST,
} from 'redux/constants/locationConstants';

export function getCountryList(payload) {
  return {
    type: GET_COUNTRY_LIST,
    payload
  };
}

export function getProvinceList(payload) {
  return {
    type: GET_PROVINCE_LIST,
    payload
  };
}

export function getDistrictList(payload) {
  return {
    type: GET_DISTRICT_LIST,
    payload
  };
}
export function getWardList(payload) {
  return {
    type: GET_WARD_LIST,
    payload
  };
}

// mutation
export function saveCountryList(countryList) {
  return {
    type: SAVE_COUNTRY_LIST,
    countryList
  };
}

export function saveProvinceList(provinceList) {
  return {
    type: SAVE_PROVINCE_LIST,
    provinceList
  };
}
export function saveDistrictList(districtList) {
  return {
    type: SAVE_DISTRICT_LIST,
    districtList
  };
}
export function saveWardList(wardList) {
  return {
    type: SAVE_WARD_LIST,
    wardList
  };
}
