import React from 'react';
// Views
const SignUp = React.lazy(() => import('views/sso/SignUp/SignUp'));
const NewPassword = React.lazy(() => import('views/sso/NewPassword/NewPassword'));
const Login = React.lazy(() => import('views/sso/Login/Login'));
const ForgotPassword = React.lazy(() => import('views/sso/ForgotPassword/ForgotPassword'));
const CheckEmail = React.lazy(() => import('views/sso/CheckEmail/CheckEmail'));

// Layout
const Layout = React.lazy(() => import('layouts/Layout/Layout'));

export default [
  {
    path: '/',
    component: Layout,
    routes: [
      {
        path: "/",
        exact: true,
        component: Login,
      },
      {
        path: "/signup",
        exact: true,
        component: SignUp,
        _name: 'Đăng ký tài khoản',
      },
      {
        path: "/new-password",
        exact: true,
        component: NewPassword,
        _name: 'Tạo mới mật khẩu',
      },
      {
        path: "/check-email",
        exact: true,
        component: CheckEmail,
        _name: 'Xác thực email',
      },
      {
        path: "/forgot-password",
        exact: true,
        component: ForgotPassword,
        _name: 'Quên mật khẩu',
      },
    ]
  }
]