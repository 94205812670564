import authReducer from './authReducer';
import wsReducer from './wsReducer';
import locationReducer from './locationReducer';
import { combineReducers } from 'redux';

export default combineReducers({
  authReducer,
  wsReducer,
  locationReducer,
});
