import variables from './variables';

export default (theme) => ({
  html: {
    fontWeight: 100,
    fontSize: 16,
  },
  body: {
    overflow: 'visible',
    color: variables.textColor,
    lineHeight: 'normal',
    fontWeight: 400,
  },
  'ul, dl, ol, li': {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    listStyleType: 'none',
  }
})