import axiosCommon from 'utils/axios/axiosCommon';
import axiosAuth from 'utils/axios/axiosAuth';

const authApi = {
  checkAuth: () => {
    return axiosCommon.post(`user/authentication/sso`, {}, {withCredentials: true});
  },
  signIn: ({ params }) => {
    return axiosCommon.post(`user/signin`, params, {withCredentials: true});
  },
  signInWithGoogle: ({ params }) => {
    return axiosCommon.post(`user/google_auth`, params, {withCredentials: true});
  },
  signUp: ({ params }) => {
    return axiosCommon.post(`user/signup`, params, {withCredentials: true});
  },
  forgotPassword: ({ params }) => {
    return axiosCommon.post(`user/forgot_password`, params, {withCredentials: true});
  },
  resetPassword: ({ params, token }) => {
    return axiosCommon.post(`user/new_password`, params, {
      headers: {
        common: {
          token: token,
        },
      },
      withCredentials: true,
    });
  },
  checkResetPasswordToken: ({ token }) => {
    return axiosCommon.get(`user/token`, {
      headers: {
        common: {
          token: token,
        },
      },
      withCredentials: true,
    });
  },

  getAllowedActions: ({ component }) => {
    return axiosAuth.get(`user/get_allowed_actions`, {
      params: { component },
    });
  },

  // upload document
  uploadDocument: ({ params }) => {
    const newParams = { ...params };
    const formData = new FormData();
    Object.keys(newParams).forEach((key) => {
      if (newParams[key]) formData.append(key, newParams[key]);
    });

    return axiosAuth.post(`upload/single`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  checkDomain: (params) => {
    return axiosAuth.get(`user/check_duplicate_tenant_url`,{
      params: {
        tenant_url: params
      }
    });
  },
  
  requestOtp: ({ params }) => {
    return axiosAuth.post(`user/request_otp`,params);
  },
  verifyOtp: ({ params }) => {
    return axiosAuth.post(`user/verify_otp`,params);
  }
};

export default authApi;
