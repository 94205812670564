import {
  CHECK_AUTH,
  SAVE_AUTH,
  REMOVE_AUTH,
  SIGN_IN,
  SAVE_AUTH_LOADING,
  SAVE_AUTH_ERROR,
  SIGN_IN_WITH_GOOGLE,
  SIGN_UP,
  SAVE_AUTH_SIGNUP_SUCCESS,
} from 'redux/constants/authConstants';

export function checkAuth(payload) {
  return {
    type: CHECK_AUTH,
    payload
  };
}
export function signIn({ 
  resolve, 
  reject,
  ...otherPayload
}) {
  return {
    type: SIGN_IN,
    payload: {
      resolve, 
      reject,
      ...otherPayload
    }
  };
}
export function signInWithGoogle({ 
  resolve, 
  reject,
  ...otherPayload
}) {
  return {
    type: SIGN_IN_WITH_GOOGLE,
    payload: {
      resolve, 
      reject,
      ...otherPayload
    }
  };
}
export function signUp(payload) {
  return {
    type: SIGN_UP,
    payload
  };
}

// mutation
export function saveAuthLoading(loading) {
  return {
    type: SAVE_AUTH_LOADING,
    loading
  };
}
export function saveAuthError(error) {
  return {
    type: SAVE_AUTH_ERROR,
    error
  };
}
export function saveAuth(user) {
  return {
    type: SAVE_AUTH,
    user
  };
}
export function removeAuth(payload) {
  return {
    type: REMOVE_AUTH,
    payload
  };
}
export function saveAuthSignUpSuccess(authSignUpSuccess) {
  return {
    type: SAVE_AUTH_SIGNUP_SUCCESS,
    authSignUpSuccess
  };
}
