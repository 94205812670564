// Color
const primaryColor = '#DDB885';
const secondaryColor = '#DDB885';
const infoColor = '#54E5EF';
const successColor = '#20973A';
const dangerColor = '#FC6579';
const bodyColor = '#F5F5F5';
const starRatingsColor = '#FFB400';
const starRatingsEmptyColor = "rgba(0, 0, 0, 0.13)";
// rgba
const backgroundRadialGradientDanger = 'radial-gradient(88.39% 88.39% at 16.07% 11.61%, #DDB885 0%, #DDB885 100%)';
const backgroundRadialGradientInfo = 'radial-gradient(88.39% 88.39% at 16.07% 11.61%, #54E5EF 0%, #16C9D6 100%)';
const backgroundRadialGradientWarning = 'radial-gradient(88.39% 88.39% at 16.07% 11.61%, #FFA000 0%, #FFC25A 0.01%, #EF9806 100%)';
const backgroundRadialGradientPrimary = backgroundRadialGradientDanger;
const backgroundRadialGradientSecondary = backgroundRadialGradientInfo;
// Typography
const textColor = '#475667';

export default {
  // Color
  primaryColor,
  infoColor,
  secondaryColor,
  successColor,
  dangerColor,
  bodyColor,
  starRatingsColor,
  starRatingsEmptyColor,
  // Background radial gradient
  backgroundRadialGradientDanger,
  backgroundRadialGradientInfo,
  backgroundRadialGradientWarning,
  backgroundRadialGradientPrimary,
  backgroundRadialGradientSecondary,
  // Typography
  textColor,
}