import variables from './variables';

export const defaultTheme = {
  palette: {
    primary: {
      main: variables.primaryColor,
    },
    error: {
      main: '#f44336',
    },
    info: {
      main: variables.infoColor,
    },
    secondary: {
      main: variables.secondaryColor,
    },
    success: {
      main: variables.successColor,
    },
    backgroundRadialGradient: {
      primary: variables.backgroundRadialGradientPrimary,
      secondary: variables.backgroundRadialGradientSecondary,
      info: variables.backgroundRadialGradientInfo,
      warning: variables.backgroundRadialGradientWarning,
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.13)',
    },
    text: {
      primary: '#1F2933',
      secondary: '#475667',
    }
  },
  typography: {
    fontFamily: ['Nunito', 'Open Sans', 'sans-serif'].join(','),
    fontSize: 14,
    button: {
      fontFamily: ['Nunito', 'Open Sans', 'sans-serif'].join(','),
      textTransform: 'none',
    },
  },
};
