import authApi from 'utils/api/authApi';
import { put } from 'redux-saga/effects';
import { authActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* checkAuthentication(payload) {
  try {
    yield put(authActions.saveAuth(null));

    const { data: response } = yield authApi.checkAuth();

    if (!response?.result) {
      yield put(authActions.saveAuth({}));

      return;
    }
    yield put(authActions.saveAuth(response.data ? {...response.data} : {}));
  } catch (err) {
    console.log(err);
  }
}
export function* signMeIn(payload) {
  const { 
    params,
    resolve = () => {}, 
    reject = () => {},
  } = payload.payload;

  try {
    yield put(authActions.saveAuthLoading(true));
    yield put(authActions.saveAuth(null));
    yield put(authActions.saveAuthError(''));

    const { data: response } = yield authApi.signIn({ params });

    yield put(authActions.saveAuthLoading(false));

    if (!response?.result) {
      yield put(authActions.saveAuth(null));
      yield put(authActions.saveAuthError(response.message));
      reject(response)
      return;
    }
    yield put(authActions.saveAuth(response.data ? {...response.data} : null));
    resolve(response)
  } catch (err) {
    console.log(err);
    reject(err)
  }
}
export function* signMeInWithGoogle(payload) {
  const { 
    params,
    resolve = () => {}, 
    reject = () => {},
   } = payload.payload;
  try {
    yield put(authActions.saveAuthLoading(true));
    yield put(authActions.saveAuth(null));
    yield put(authActions.saveAuthError(''));

    const { data: response } = yield authApi.signInWithGoogle({ params });

    yield put(authActions.saveAuthLoading(false));

    if (!response?.result) {
      yield put(authActions.saveAuth(null));
      yield put(authActions.saveAuthError(response.message));
      reject(response)
      return;
    }
    yield put(authActions.saveAuth(response.data ? {...response.data} : null));
    resolve(response)
  } catch (err) {
    console.log(err);
    reject(err)
  }
}
export function* signMeUp(payload) {
  const { params } = payload.payload;
  try {
    yield put(authActions.saveAuthLoading(true));
    yield put(authActions.saveAuthSignUpSuccess(false));

    const { data: response } = yield authApi.signUp({ params });

    yield put(authActions.saveAuthLoading(false));

    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }
 
    yield put(authActions.saveAuthSignUpSuccess(true));
  } catch (err) {
    console.log(err);
  }
}
