import { takeLatest } from 'redux-saga/effects';

import { CHECK_AUTH, SIGN_IN, SIGN_IN_WITH_GOOGLE, SIGN_UP } from 'redux/constants/authConstants';
import { checkAuthentication, signMeIn, signMeInWithGoogle, signMeUp } from 'saga/authSaga';

import {
  GET_COUNTRY_LIST,
  GET_PROVINCE_LIST,
  GET_DISTRICT_LIST,
  GET_WARD_LIST,
} from 'redux/constants/locationConstants';
import {
  loadCountryList,
  loadProvinceList,
  loadDistrictList,
  loadWardList,
} from 'saga/locationSaga';

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount

  // auth
  yield takeLatest(CHECK_AUTH, checkAuthentication);
  yield takeLatest(SIGN_IN, signMeIn);
  yield takeLatest(SIGN_IN_WITH_GOOGLE, signMeInWithGoogle);
  yield takeLatest(SIGN_UP, signMeUp);

  // location
  yield takeLatest(GET_COUNTRY_LIST, loadCountryList);
  yield takeLatest(GET_PROVINCE_LIST, loadProvinceList);
  yield takeLatest(GET_DISTRICT_LIST, loadDistrictList);
  yield takeLatest(GET_WARD_LIST, loadWardList);
}
